* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  border: none;
}

@font-face {
  font-family: "Switzer";
  src: url("../public/fonts/Switzer-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: local("PlusJakartaSans"),
    url(../public/fonts/PlusJakartaSans-Regular.ttf) format("truetype");
}
